import Vue from 'vue';

const store = Vue.observable({
  settings: {
    completedTasksNumDays: 0,
    completedTasksReadOnlyNumDays: 0,
  },
});

export default store;
