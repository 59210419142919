<template>
  <div class="tasksearch">
    <input
      class="tasksearch-input"
      placeholder="Type to search tasks"
      @input="searchTasks"
      @blur="hideDropdown"
      v-model="searchString"
    />
    <div class="tasksearch-dropdown" v-if="dropdownVisible">
      <ul class="tasksearch-results-list">
        <li
          class="tasksearch-result"
          v-for="result in taskList"
          :key="result.TaskID"
          @click="selectTask(result)"
          @mousedown.prevent
        >
          {{ result.Name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['projectid', 'taskid', 'selected-tasks'],

  data() {
    return {
      taskList: [],

      selectedTask: {},
      searchString: '',

      dropdownVisible: false,
    };
  },

  methods: {
    searchTasks() {
      if (!this.searchString) {
        this.taskList = [];
        return;
      }

      this.$http
        .get(`${process.env.VUE_APP_API}/tasks/search`, {
          params: {
            projectID: this.projectid,
            taskID: this.taskid,
            searchString: this.searchString,
          },
        })
        .then((result) => {
          this.taskList = result.data;
          this.taskList = this.taskList.filter(
            (task) =>
              !this.selectedTasks.some((s) => s.RelatedTaskID === task.TaskID)
          );
          this.selectedTask = {};
          this.showDropdown();
        });
    },

    showDropdown() {
      this.dropdownVisible = true;
    },

    hideDropdown() {
      this.dropdownVisible = false;
    },

    selectTask(task) {
      this.selectedTask = task;
      this.searchString = task.Name;
      this.hideDropdown();
    },
  },
};
</script>

<style scoped>
.tasksearch {
  position: relative;
  width: 100%;
}

.tasksearch-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.tasksearch-results-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tasksearch-result {
  cursor: pointer;
}

.tasksearch-result:hover,
.tasksearch-result:focus,
.tasksearch-result:active {
  background-color: #d0dfff;
}

.tasksearch-input {
  width: 100%;
}
</style>
