<template>
  <div>
    <div v-if="displayHeader" class="project-grid">
      <div>
        <span class="column-heading">Projects</span>
      </div>
      <div v-if="taskStatuses" class="swimlane-header">
        <div>
          <span class="column-heading">{{ taskStatuses[0].StatusName }}</span>
        </div>
        <div>
          <span class="column-heading">{{ taskStatuses[1].StatusName }}</span>
        </div>
        <div>
          <span class="column-heading">{{ taskStatuses[2].StatusName }}</span>
        </div>
      </div>
    </div>
    <div class="project-grid">
      <div>
        <div class="project-column">
          <div class="project-form">
            <div v-if="!editProject">
              <span class="project-title" @click="editProject = true">{{
                project.Name
              }}</span>
            </div>
            <div v-if="editProject">
              <div>
                <input
                  class="project-title-edit"
                  type="text"
                  v-model="editedProject.Name"
                />
              </div>
              <div class="project-buttons">
                <div class="delete-button">
                  <button
                    class="button-red"
                    v-if="project.Tasks === 0"
                    @click="deleteProject"
                  >
                    Delete
                  </button>
                </div>
                <div class="save-buttons">
                  <button @click="updateProject">Save</button>
                  <button @click="editProject = false">Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <group-form
              :groups="projectGroups"
              :projectid="project.ProjectID"
              v-on:get-groups="getGroups"
              v-on:get-tasks="getTasks"
              v-on:set-task-group-filter="setTaskGroupFilter"
            ></group-form>
          </div>
          <div>
            <task-form
              :edited-task="selectedTask"
              v-on:add-task="addTask"
              v-on:edit-task="editTask($event)"
              v-on:cancel-task="selectedTask = {}"
              v-on:save-notes="editTaskNotes($event)"
              v-on:get-tasks="getTasks"
              :groups="projectGroups"
              :projectid="project.ProjectID"
              ref="taskForm"
            ></task-form>
          </div>
        </div>
      </div>
      <div>
        <div class="swimlane-grid">
          <div
            class="swimlane"
            @drop="onDrop($event, 1)"
            @dragover.prevent
            @dragenter.prevent="onDragEnter($event, 1)"
            @dragleave="onDragLeave($event, 1)"
            @dragend="onDragEnd"
          >
            <transition-group name="list">
              <task-card
                v-for="(task, index) in notStarted"
                :key="task.TaskID"
                :selected="selectedTask.TaskID === task.TaskID"
                :task="task"
                v-on:select-task="selectTask(task, 1)"
                :data-index="index"
                draggable
                v-on:dragstart="startDrag($event, task, 1)"
                v-on:move-to-backlog="moveToBacklog(task)"
              ></task-card>
            </transition-group>
          </div>
          <div
            class="swimlane"
            @drop="onDrop($event, 2)"
            @dragover.prevent
            @dragenter.prevent="onDragEnter($event, 2)"
            @dragleave="onDragLeave($event, 2)"
            @dragend="onDragEnd"
          >
            <transition-group name="list">
              <task-card
                v-for="(task, index) in inProgress"
                :key="task.TaskID"
                :selected="selectedTask.TaskID === task.TaskID"
                :task="task"
                v-on:select-task="selectTask(task, 2)"
                :data-index="index"
                draggable
                v-on:dragstart="startDrag($event, task, 2)"
                v-on:move-to-backlog="moveToBacklog(task)"
              ></task-card>
            </transition-group>
          </div>
          <div
            class="swimlane"
            @drop="onDrop($event, 3)"
            @dragover.prevent
            @dragenter.prevent="onDragEnter($event, 3)"
            @dragleave="onDragLeave($event, 3)"
            @dragend="onDragEnd"
          >
            <transition-group name="list">
              <task-card
                class="task"
                v-for="(task, index) in done"
                :key="task.TaskID"
                :selected="selectedTask.TaskID === task.TaskID"
                :task="task"
                v-on:select-task="selectTask(task, 3)"
                :data-index="index"
                :draggable="!task.readOnly"
                v-on:dragstart="startDrag($event, task, 3)"
              ></task-card
            ></transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskCard from './TaskCard.vue';
import taskForm from './TaskForm.vue';
import groupForm from './GroupForm.vue';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export default {
  components: {
    taskCard,
    taskForm,
    groupForm,
  },

  props: [
    'project',
    'display-header',
    'task-filter-text',
    'task-type-filter',
    'task-completed-date-filter',
  ],

  data() {
    return {
      taskStatuses: null,

      notStarted: [],
      notStartedUnfiltered: [],
      inProgress: [],
      inProgressUnfiltered: [],
      done: [],
      doneUnfiltered: [],

      selectedTask: {},
      editProject: false,
      editedProject: {},

      projectGroups: [],
      taskGroupFilter: '-1',

      dragList: -1,
      currentIndex: 0,
      sortIndex: 0,
      dragItem: null,
      dragInitialTarget: null,
      tempList: [],
    };
  },

  methods: {
    getTasks() {
      let incompleteTasks = [];

      // Get tasks for project
      this.$http
        .get(`${process.env.VUE_APP_API}/tasks/incomplete`, {
          params: { projectID: this.project.ProjectID },
        })
        .then((result) => {
          incompleteTasks = result.data;

          this.notStarted = [];
          this.inProgress = [];

          // Split tasks based on status
          incompleteTasks.forEach((task) => {
            if (task.TaskStatusID === 1) {
              this.notStarted.push(task);
            } else if (task.TaskStatusID === 2) {
              this.inProgress.push(task);
            }
          });

          this.notStartedUnfiltered = this.notStarted.filter(() => true);
          this.inProgressUnfiltered = this.inProgress.filter(() => true);

          this.getCompletedTasks(this.taskCompletedDateFilter);
        });
    },

    getCompletedTasks(dateFilter) {
      const numDays =
        (new Date(new Date().toDateString()) -
          new Date(new Date(dateFilter).toDateString())) /
        MS_PER_DAY;

      this.$http
        .get(`${process.env.VUE_APP_API}/tasks/completed`, {
          params: { projectID: this.project.ProjectID, numDays },
        })
        .then((result) => {
          this.done = result.data;

          this.done.forEach((item, index) => {
            this.done[index].readOnly = this.readOnlyTask(item);
          });

          // Sort completed tasks by date
          this.done.sort(
            (a, b) => new Date(b.CompletedOn) - new Date(a.CompletedOn)
          );

          this.doneUnfiltered = this.done.filter(() => true);

          if (this.selectedTask.TaskID) {
            this.setSelectedTask(
              this.selectedTask,
              -1 // Task may have changed status
            );
          }
        });
    },

    updateSort(list) {
      const sortList = list;
      // Update sort order for list that has been edited
      sortList.forEach((item, index) => {
        // Iterate and set index as sort order
        sortList[index].SortOrder = index;
      });

      // Trigger update for project tasks
      this.$http.put(`${process.env.VUE_APP_API}/tasks`, list);
    },

    updateSortOrder(list, task, statusID, isBelow) {
      const index = list.findIndex((item) => item.TaskID === task.TaskID);
      const adjacentTask = isBelow
        ? list[index - 1].TaskID
        : list[index + 1].TaskID;
      const originalList = this.getListUnfiltered(statusID);

      const originalIndex = originalList.findIndex(
        (item) => item.TaskID === task.TaskID
      );
      const adjacentIndex = originalList.findIndex(
        (item) => item.TaskID === adjacentTask
      );

      originalList.splice(originalIndex, 1);
      originalList.splice(adjacentIndex, 0, task);

      this.updateSort(originalList);
    },

    addTask(task) {
      const newTask = task;
      newTask.ProjectID = this.project.ProjectID;
      newTask.SortOrder = this.notStarted.length;
      newTask.TaskStatusID = 1;

      this.$refs.taskForm.isSaving = true;
      this.$http
        .post(`${process.env.VUE_APP_API}/tasks/newTask`, newTask)
        .then((result) => {
          this.selectedTask.TaskID = result.data.insertId;
          this.selectedTask.TaskStatusID = 1;
          this.getTasks();
          this.$refs.taskForm.isSaving = false;
          this.$refs.taskForm.clearUnsavedChanges();
        });
    },

    editTask(data) {
      this.selectedTask.Name = data.Name;
      this.selectedTask.Details = data.Details;
      this.selectedTask.Notes = data.Notes;
      this.selectedTask.TaskTypeID = data.TaskTypeID;
      this.selectedTask.taskGroups = data.taskGroups;
      this.selectedTask.relatedTasks = data.relatedTasks;
      this.$refs.taskForm.isSaving = true;

      this.$http
        .put(`${process.env.VUE_APP_API}/tasks/editTask`, this.selectedTask)
        .then(() => {
          this.getTasks();
          this.$refs.taskForm.isSaving = false;
          this.$refs.taskForm.clearUnsavedChanges();
        });
    },

    editTaskNotes(data) {
      this.selectedTask.Notes = data;

      this.$http.put(
        `${process.env.VUE_APP_API}/tasks/editNotes`,
        this.selectedTask
      );
    },

    updateProject() {
      this.$http
        .put(`${process.env.VUE_APP_API}/projects`, this.editedProject)
        .then(() => {
          this.$emit('get-projects');
          this.$nextTick((this.editProject = false));
        });
    },

    deleteProject() {
      this.$http
        .delete(`${process.env.VUE_APP_API}/projects`, {
          params: { projectID: this.project.ProjectID },
        })
        .then(() => this.$emit('get-projects'));
    },

    selectTask(select, statusID) {
      if (this.selectedTask.TaskID === select.TaskID) {
        this.selectedTask = {};
        return;
      }

      this.setSelectedTask(select, statusID);
    },

    setSelectedTask(select, statusID) {
      if (statusID === 1 || statusID === -1) {
        this.selectedTask = this.notStarted.find(
          (task) => task.TaskID === select.TaskID
        );
      }
      if (statusID === 2 || (statusID === -1 && !this.selectedTask)) {
        this.selectedTask = this.inProgress.find(
          (task) => task.TaskID === select.TaskID
        );
      }
      if (statusID === 3 || (statusID === -1 && !this.selectedTask)) {
        this.selectedTask = this.done.find(
          (task) => task.TaskID === select.TaskID
        );
      }
    },

    readOnlyTask(task) {
      if (
        new Date(task.CompletedOn) >
        Date.now() -
          this.$store.settings.completedTasksReadOnlyNumDays * MS_PER_DAY
      ) {
        return false;
      }
      return true;
    },

    getGroups(groups) {
      this.projectGroups = groups;
    },

    applyFilters() {
      this.notStarted = this.filterTaskListByText(this.notStartedUnfiltered);
      this.inProgress = this.filterTaskListByText(this.inProgressUnfiltered);
      this.done = this.filterTaskListByText(this.doneUnfiltered);

      this.notStarted = this.filterTaskListByType(this.notStarted);
      this.inProgress = this.filterTaskListByType(this.inProgress);
      this.done = this.filterTaskListByType(this.done);

      this.notStarted = this.filterTaskListByGroup(this.notStarted);
      this.inProgress = this.filterTaskListByGroup(this.inProgress);
      this.done = this.filterTaskListByGroup(this.done);
    },

    filterTaskListByText(list) {
      return list.filter((task) =>
        task.Name.toLowerCase().match(
          this.taskFilterText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        )
      );
    },

    filterTaskListByType(list) {
      return list.filter(
        (task) =>
          task.TaskTypeID === this.taskTypeFilter ||
          this.taskTypeFilter === '-1'
      );
    },

    filterTaskListByGroup(list) {
      if (this.taskGroupFilter !== '-1') {
        return list.filter((task) =>
          task.taskGroups.some(
            (group) => group.TaskGroupID === this.taskGroupFilter
          )
        );
      }
      return list;
    },

    setTaskGroupFilter(group) {
      if (this.taskGroupFilter !== group.TaskGroupID) {
        this.taskGroupFilter = group.TaskGroupID;
      } else {
        this.taskGroupFilter = '-1';
      }
    },

    getStatuses() {
      this.$http.get(`${process.env.VUE_APP_API}/statuses`).then((result) => {
        this.taskStatuses = result.data;
      });
    },

    startDrag(evt, item, statusID) {
      const event = evt;
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';

      this.dragItem = item;
      this.dragInitialTarget = evt.target;

      this.dragList = statusID;
      this.currentIndex = evt.target.dataset.index;
      this.tempList = this.getList(statusID).filter(() => true);
    },

    onDrop(evt, statusID) {
      if (!this.dragItem) return;

      if (statusID !== this.dragList) {
        const itemList = this.getList(this.dragList);
        const foundItem = itemList.find(
          (item) => item.TaskID === this.dragItem.TaskID
        );

        itemList.splice(
          itemList.findIndex((item) => item.TaskID === this.dragItem.TaskID),
          1
        );
        this.updateTaskStatus(foundItem, statusID);

        this.updateSort(itemList);
      } else {
        const tempIndex = this.tempList.findIndex(
          (item) => item.TaskID === this.dragItem.TaskID
        );
        const currIndex = this.getList(this.dragList).findIndex(
          (item) => item.TaskID === this.dragItem.TaskID
        );

        if (tempIndex !== currIndex) {
          if (tempIndex > currIndex) {
            this.updateSortOrder(
              this.getList(this.dragList),
              this.dragItem,
              statusID,
              false
            );
          } else if (tempIndex < currIndex) {
            this.updateSortOrder(
              this.getList(this.dragList),
              this.dragItem,
              statusID,
              true
            );
          }
        }
      }

      evt.currentTarget.classList.remove('drag-enter');
    },

    onDragEnter(evt, statusID) {
      if (!this.dragItem) return;

      evt.currentTarget.classList.add('drag-enter');

      const { target } = evt;

      if (this.dragList === statusID) {
        if (this.dragList !== 3) {
          if (target.parentNode.classList.contains('card')) {
            if (
              this.sortIndex !== parseInt(target.parentNode.dataset.index, 10)
            ) {
              this.sortIndex = parseInt(target.parentNode.dataset.index, 10);
              const itemList = this.getList(statusID);

              const itemIndex = itemList.findIndex(
                (item) => item.TaskID === this.dragItem.TaskID
              );
              itemList.splice(
                this.sortIndex,
                0,
                itemList.splice(itemIndex, 1)[0]
              );
            }
          }
        }

        this.dragInitialTarget.classList.remove('drag-hide');
      }
    },

    onDragLeave(evt, statusID) {
      if (!this.dragItem) return;

      if (evt.currentTarget.contains(evt.relatedTarget)) {
        return;
      }
      evt.currentTarget.classList.remove('drag-enter');

      if (this.dragList === statusID) {
        this.dragInitialTarget.classList.add('drag-hide');
      }
    },

    onDragEnd(evt) {
      if (!this.dragItem) return;

      if (evt.dataTransfer.dropEffect === 'none') {
        if (this.dragList === 1) {
          this.notStarted = this.tempList;
        } else if (this.dragList === 2) {
          this.inProgress = this.tempList;
        } else if (this.dragList === 3) {
          this.done = this.tempList;
        }
      }

      this.dragList = -1;
      this.dragItem = null;
      this.tempList = null;
      this.dragInitialTarget.classList.remove('drag-hide');
    },

    getList(statusID) {
      if (statusID === 1) {
        return this.notStarted;
      }
      if (statusID === 2) {
        return this.inProgress;
      }
      return this.done;
    },

    getListUnfiltered(statusID) {
      if (statusID === 1) {
        return this.notStartedUnfiltered;
      }
      if (statusID === 2) {
        return this.inProgressUnfiltered;
      }
      return this.doneUnfiltered;
    },

    updateTaskStatus(task, statusID) {
      const updateTask = task;
      updateTask.SortOrder = this.getList(statusID).length;

      this.$http
        .post(`${process.env.VUE_APP_API}/tasks/updateStatus`, {
          taskID: updateTask.TaskID,
          status: statusID,
        })
        .then(() => {
          this.getTasks();
        });
    },

    moveToBacklog(task) {
      this.$http
        .post(`${process.env.VUE_APP_API}/tasks/updateStatus`, {
          taskID: task.TaskID,
          status: 4,
        })
        .then(() => {
          this.selectedTask = {};
          this.getTasks();
        });
    },
  },

  watch: {
    taskFilterText() {
      this.applyFilters();
    },

    taskTypeFilter() {
      this.applyFilters();
    },

    taskGroupFilter() {
      this.applyFilters();
    },

    taskCompletedDateFilter() {
      this.getCompletedTasks(this.taskCompletedDateFilter);
    },
  },

  created() {
    this.getTasks();
    this.getStatuses();

    Object.assign(this.editedProject, this.project);
  },
};
</script>

<style scoped>
.column-heading {
  color: #454545;
  font-weight: bold;
}

.project-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 2px;
}

.swimlane-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1px;
}

.swimlane-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1px;
  height: 100%;
}

.swimlane {
  height: 100%;
  padding: 0 10px 0 10px;
  background-color: #ddd;
}

.project-column {
  margin-right: 2px;
  background-color: #f0f0f0;
  height: 100%;
}

.project-form {
  padding: 2px 0px;
}

.project-title {
  font-size: 16px;
}

.project-title:hover {
  cursor: pointer;
}

.project-title-edit {
  font-weight: bold;
  font-size: 16px;
  width: calc(100% - 8px);
}

.project-buttons {
  display: flex;
}

.delete-button {
  flex: 1;
}

.save-buttons {
  flex: 1;
  text-align: right;
}

.drag-enter {
  filter: brightness(80%);
}

.drag-over {
  filter: brightness(10%);
}

.drag-hide {
  display: none;
}

.list-move {
  transition: transform 0.3s;
}
</style>
