<template>
  <div>
    <div>
      <list-button @click="setShowList">{{ label }}</list-button>
    </div>
    <transition name="fadefilter">
      <div class="project-list" v-if="showList" :style="cssFilter">
        <div
          class="project-list-item"
          v-for="project in projects"
          :key="project.ProjectID"
          @click="projectSelected(project.ProjectID)"
        >
          <input
            class="project-checkbox"
            type="checkbox"
            :checked="visibleProjectList.includes(project.ProjectID)"
          />
          {{ project.Name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ListButton from '@/components/ListButton.vue';

export default {
  props: ['projects', 'visible-project-list', 'label'],

  components: {
    ListButton,
  },

  data() {
    return {
      showList: false,
    };
  },

  methods: {
    projectSelected(projectID) {
      const index = this.visibleProjectList.indexOf(projectID);
      if (index > -1) {
        this.visibleProjectList.splice(index, 1);
      } else {
        this.visibleProjectList.push(projectID);
        this.visibleProjectList.sort();
      }
      this.$emit('list-changed', this.visibleProjectList);
    },

    setShowList(value) {
      this.showList = value;
    },
  },

  computed: {
    cssFilter() {
      return {
        '--height': `${this.projects.length * 20}px`,
      };
    },
  },
};
</script>

<style scoped>
.project-list {
  background-color: #ececec;
  border-radius: 4px;
  padding: 4px;
  max-width: fit-content;
  overflow: hidden;
  max-width: 100%;
}

.project-list-item {
  cursor: pointer;
  display: flex;
}

.project-checkbox {
  cursor: pointer;
}

.fadefilter-enter-active,
.fadefilter-leave-active {
  transition: all 0.5s;
  max-height: var(--height);
}

.fadefilter-enter,
.fadefilter-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
