<template>
  <div>
    <div>
      <button
        v-if="!showTaskForm || (showTaskForm && editedTask.TaskID)"
        class="button-blue"
        @click="newTask()"
      >
        New Task
      </button>
    </div>
    <div v-if="showTaskForm">
      <div v-if="editedTask.TaskID">
        <span class="task-heading">Edit Task</span>
      </div>
      <div v-else>
        <span class="task-heading">New Task</span>
      </div>
      <div class="editor-row">
        <div class="label-column">Name</div>
        <div class="details-column">
          <textarea v-model="task.Name" :disabled="editedTask.readOnly" />
        </div>
      </div>
      <div class="editor-row">
        <div class="label-column">Type</div>
        <div class="details-column">
          <select v-model="task.TaskTypeID" :disabled="editedTask.readOnly">
            <option
              v-for="type in taskTypes"
              :key="type.TaskTypeID"
              :value="type.TaskTypeID"
            >
              {{ type.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="editor-row">
        <div class="label-column">Details</div>
        <div class="details-column">
          <textarea
            v-model="task.Details"
            rows="5"
            :disabled="editedTask.readOnly"
          />
        </div>
      </div>
      <div v-if="editedTask.TaskStatusID === 3" class="editor-row">
        <div class="label-column">Done</div>
        <div class="details-column">
          {{
            new Date(editedTask.CompletedOn + ' +00:00').toLocaleString('en-AU')
          }}
        </div>
      </div>
      <div class="editor-row">
        <div class="label-column">Groups</div>
        <div class="details-column">
          <div
            v-for="group in task.taskGroups"
            :key="group.TaskGroupID"
            class="table-row"
          >
            <div class="table-text">
              {{ group.Name }}
            </div>
            <div class="table-button">
              <button
                class="button-remove icon-button-red"
                @click="removeGroupFromTask(group)"
              >
                <img
                  style="height: 14px"
                  src="@/assets/delete_FILL0_wght700_GRAD0_opsz48.svg"
                />
              </button>
            </div>
          </div>
          <div v-if="!addingGroup">
            <button class="button-add icon-button" @click="showAddGroup()">
              <img
                style="height: 14px"
                src="@/assets/add_FILL0_wght700_GRAD0_opsz48.svg"
              />
            </button>
          </div>
          <div v-if="addingGroup">
            <select v-model="selectedGroup">
              <option
                v-for="group in filteredGroups"
                :key="group.TaskGroupID"
                :value="group"
              >
                {{ group.Name }}
              </option>
            </select>
            <button class="button-small" @click="addGroupToTask()">Add</button>
            <button class="button-small" @click="addingGroup = false">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="editor-row">
        <div class="label-column">Related</div>
        <div class="details-column">
          <div
            v-for="relatedTask in task.relatedTasks"
            :key="relatedTask.RelatedTaskID"
            class="table-row"
          >
            <div class="table-text">
              {{ relatedTask.Name }}
            </div>
            <div class="table-button">
              <button
                class="button-remove icon-button-red"
                @click="removeRelatedTask(relatedTask)"
              >
                <img
                  class="icon-white"
                  style="height: 14px"
                  src="@/assets/delete_FILL0_wght700_GRAD0_opsz48.svg"
                />
              </button>
            </div>
          </div>
          <div v-if="!addingRelatedTask">
            <button
              class="button-add icon-button"
              @click="addingRelatedTask = true"
            >
              <img
                class="icon-white"
                style="height: 14px"
                src="@/assets/add_FILL0_wght700_GRAD0_opsz48.svg"
              />
            </button>
          </div>
          <div v-if="addingRelatedTask">
            <task-search
              ref="taskSearch"
              class="tasksearch"
              :projectid="projectid"
              :taskid="task.TaskID"
              :selected-tasks="task.relatedTasks"
            />
            <button class="button-small" @click="addRelatedTask()">Add</button>
            <button class="button-small" @click="addingRelatedTask = false">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="editor-row">
        <div
          v-if="
            (editedTask.TaskStatusID === 1 ||
              editedTask.TaskStatusID === undefined) &&
            editedTask.TaskID
          "
        >
          <button class="button-red" @click="deleteTask()">Delete</button>
        </div>
        <div class="save-buttons">
          <button
            v-if="!editedTask.readOnly && unsavedChanges()"
            :active="isSaving"
            @click="saveTask()"
          >
            <span v-if="isSaving">Saving...</span>
            <span v-else>Save</span>
          </button>
          <button @click="cancel()">
            {{ editedTask.TaskID ? 'Close' : 'Cancel' }}
          </button>
        </div>
      </div>
      <div v-if="editedTask.TaskID">
        <br />
        <div>Notes</div>
        <div>
          <textarea v-model="task.Notes" rows="10" @blur="saveNotes()" />
        </div>
      </div>
      <div v-if="editedTask.TaskID && editedTask.TaskStatusID">
        <div class="editor-row">
          <div class="label-column">Time Spent</div>
          <div
            v-if="!showAmendTime"
            class="details-column pointer"
            @click="amendTimer"
          >
            {{ generateTimerString(task.TimeSpent) }}
          </div>
          <div v-if="showAmendTime" class="details-column">
            <div>
              <input
                v-model="timerHours"
                class="input-time"
                type="number"
                @focus="$event.target.select()"
              />
              <input
                v-model="timerMinutes"
                class="input-time"
                type="number"
                @focus="$event.target.select()"
              />
              <input
                v-model="timerSeconds"
                class="input-time"
                type="number"
                @focus="$event.target.select()"
              />
            </div>
            <div>
              <button class="button-small" @click="saveAmended">Amend</button>
              <button class="button-small" @click="showAmendTime = false">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="editor-row">
          <div class="label-column">Timer</div>
          <div class="details-column">
            <div>
              {{ timerDisplay }}
              <button
                v-if="!timerInterval"
                class="button-small"
                @click="startTimer"
              >
                Start
              </button>
              <button
                v-if="timerInterval"
                class="button-small"
                @click="stopTimer"
              >
                Stop
              </button>
              <button
                v-if="!timerInterval && timerTotal > 0"
                class="button-small"
                @click="saveTimer"
              >
                Save
              </button>
              <button
                v-if="!timerInterval && timerTotal > 0"
                class="button-small"
                @click="clearTimer"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="editedTask.TaskID && statusHistory.length > 0">
        <div>History</div>
        <div
          v-for="status in statusHistory"
          :key="status.Timestamp"
          class="table-row"
        >
          <div class="table-status-column">
            {{ status.StatusName }}
          </div>
          <div class="table-timestamp-column">
            {{
              new Date(status.Timestamp + ' +00:00').toLocaleString('en-AU')
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskSearch from './TaskSearch.vue';

export default {
  components: {
    taskSearch,
  },

  props: ['editedTask', 'groups', 'projectid'],

  data() {
    return {
      showTaskForm: false,
      addingGroup: false,
      addingRelatedTask: false,
      addingNewTask: false,
      isSaving: false,

      taskTypes: [],
      task: {
        Name: '',
        Details: '',
        Notes: '',
        TaskTypeID: 1,
        taskGroups: [],
        relatedTasks: [],
      },

      isModified: {
        name: false,
        details: false,
        taskType: false,
        taskGroups: false,
        relatedTasks: false,
      },

      selectedGroup: {},
      selectedTask: {},

      filteredGroups: [],

      statusHistory: [],

      showAmendTime: false,
      timerInterval: null,
      timerTotal: 0,
      timerSeconds: 0,
      timerMinutes: 0,
      timerHours: 0,
      timerOffset: null,
      timerDisplay: '0:00:00',
    };
  },

  watch: {
    editedTask() {
      if (this.editedTask.TaskID) {
        this.task.Name = this.editedTask.Name;
        this.task.Details = this.editedTask.Details;
        this.task.Notes = this.editedTask.Notes;
        this.task.TaskTypeID = this.editedTask.TaskTypeID;
        this.task.taskGroups = this.editedTask.taskGroups.filter(() => true);
        this.task.relatedTasks = this.editedTask.relatedTasks.filter(
          () => true
        );
        this.task.TimeSpent = this.editedTask.TimeSpent;

        this.addingGroup = false;
        this.addingRelatedTask = false;
        this.clearUnsavedChanges();

        this.getTaskHistory();
        this.getTimer();

        this.addingNewTask = false;
        this.showAmendTime = false;
        this.showTaskForm = true;
      } else {
        // Edit has been cancelled
        if (this.addingNewTask === false) {
          this.showTaskForm = false;
        }
        this.task.Name = '';
        this.task.Details = '';
        this.task.Notes = '';
        this.task.TaskTypeID = 1;
        this.task.taskGroups = [];
        this.task.relatedTasks = [];

        this.addingGroup = false;
        this.addingRelatedTask = false;

        this.$nextTick(() => {
          this.clearUnsavedChanges();
        });
      }
    },

    // eslint-disable-next-line func-names
    'task.Name': function (newVal) {
      this.isModified.name = newVal !== this.editedTask.Name;
    },

    // eslint-disable-next-line func-names
    'task.TaskTypeID': function (newVal) {
      this.isModified.taskType = newVal !== this.editedTask.TaskTypeID;
    },

    // eslint-disable-next-line func-names
    'task.Details': function (newVal) {
      this.isModified.details = newVal !== this.editedTask.Details;
    },

    // eslint-disable-next-line func-names
    'task.taskGroups': function (newVal) {
      if (!this.editedTask.TaskID) return;

      const match = newVal.reduce(
        (a, group) =>
          a &&
          this.editedTask.taskGroups.some(
            (g) => g.TaskGroupID === group.TaskGroupID
          ),
        true
      );

      if (!match || newVal.length !== this.editedTask.taskGroups.length) {
        this.isModified.taskGroups = true;
      } else {
        this.isModified.taskGroups = false;
      }
    },

    // eslint-disable-next-line func-names
    'task.relatedTasks': function (newVal) {
      if (!this.editedTask.TaskID) return;

      const match = newVal.reduce(
        (a, task) =>
          a &&
          this.editedTask.relatedTasks.some((t) => t.TaskID === task.TaskID),
        true
      );

      if (!match || newVal.length !== this.editedTask.relatedTasks.length) {
        this.isModified.relatedTasks = true;
      } else {
        this.isModified.relatedTasks = false;
      }
    },
  },

  created() {
    // Populate task types list
    this.$http.get(`${process.env.VUE_APP_API}/tasktypes`).then((result) => {
      this.taskTypes = result.data;
    });
  },

  methods: {
    newTask() {
      this.addingNewTask = true;
      this.showTaskForm = true;
      this.$emit('cancel-task');
    },

    deleteTask() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Confirm delete?')) {
        this.close();
        this.$http
          .delete(`${process.env.VUE_APP_API}/tasks`, {
            params: { taskID: this.editedTask.TaskID },
          })
          .then(() => {
            this.$emit('cancel-task');
            this.$emit('get-tasks');
          });
      }
    },

    saveTask() {
      if (this.editedTask.TaskID) {
        this.editTask(this.task);
      } else {
        this.addingNewTask = false;
        this.$emit('add-task', this.task);
      }
    },

    cancel() {
      this.close();
      this.$emit('cancel-task');
    },

    saveNotes() {
      this.$emit('save-notes', this.task.Notes);
    },

    editTask(data) {
      this.$emit('edit-task', data);
    },

    showAddGroup() {
      this.addingGroup = true;
      this.filterGroups();
    },

    filterGroups() {
      this.filteredGroups = this.groups.filter(
        (group) =>
          !this.task.taskGroups.some((g) => g.TaskGroupID === group.TaskGroupID)
      );
      this.selectedGroup = null;
    },

    addGroupToTask() {
      if (this.selectedGroup) {
        this.task.taskGroups.push(this.selectedGroup);
        this.filterGroups();
        this.addingGroup = false;
      }
    },

    removeGroupFromTask(group) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Remove group?')) {
        this.task.taskGroups = this.task.taskGroups.filter(
          (g) => g.TaskGroupID !== group.TaskGroupID
        );
        this.filterGroups();
      }
    },

    addRelatedTask() {
      if (this.$refs.taskSearch.selectedTask) {
        const relatedTask = {
          TaskID: this.editedTask.TaskID || -1,
          RelatedTaskID: this.$refs.taskSearch.selectedTask.TaskID,
          Name: this.$refs.taskSearch.selectedTask.Name,
        };

        this.task.relatedTasks.push(relatedTask);
        this.addingRelatedTask = false;
      }
    },

    removeRelatedTask(relatedTask) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Remove related task?')) {
        this.task.relatedTasks = this.task.relatedTasks.filter(
          (r) => r.RelatedTaskID !== relatedTask.RelatedTaskID
        );
      }
    },

    close() {
      this.addingNewTask = false;
      this.showTaskForm = false;
    },

    unsavedChanges() {
      if (
        this.isModified.name ||
        this.isModified.details ||
        this.isModified.taskType ||
        this.isModified.taskGroups ||
        this.isModified.relatedTasks
      ) {
        return true;
      }
      return false;
    },

    clearUnsavedChanges() {
      this.isModified.name = false;
      this.isModified.details = false;
      this.isModified.taskType = false;
      this.isModified.taskGroups = false;
      this.isModified.relatedTasks = false;
    },

    getTaskHistory() {
      this.$http
        .get(`${process.env.VUE_APP_API}/tasks/history`, {
          params: { taskID: this.editedTask.TaskID },
        })
        .then((result) => {
          this.statusHistory = result.data;
        });
    },

    startTimer() {
      this.timerOffset = Date.now();

      this.$http
        .post(`${process.env.VUE_APP_API}/tasks/timer`, {
          taskID: this.editedTask.TaskID,
          timestamp: this.timerOffset,
          additionalTime: this.timerTotal,
        })
        .then(() => {
          this.timerInterval = setInterval(this.updateTimer, 100);
        });
    },

    stopTimer() {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
      this.timerOffset = null;

      this.$http.post(`${process.env.VUE_APP_API}/tasks/timer`, {
        taskID: this.editedTask.TaskID,
        timestamp: null,
        additionalTime: this.timerTotal,
      });
    },

    updateTimer() {
      const now = Date.now();
      const d = now - (this.timerOffset ? this.timerOffset : now);
      this.timerOffset = now;

      this.timerTotal += d;

      this.timerDisplay = this.generateTimerString(this.timerTotal);
    },

    generateTimerString(time) {
      const s = Math.floor(time / 1000);
      const m = Math.floor(s / 60);
      const h = Math.floor(m / 60);

      let seconds = `${s % 60}`;
      let minutes = `${m % 60}`;
      if (seconds.length < 2) {
        seconds = `0${seconds}`;
      }
      if (minutes.length < 2) {
        minutes = `0${minutes}`;
      }
      const timeString = `${h}:${minutes}:${seconds}`;

      return timeString;
    },

    saveTimer() {
      this.$http
        .put(`${process.env.VUE_APP_API}/tasks/timer`, {
          taskID: this.editedTask.TaskID,
        })
        .then((response) => {
          this.timerTotal = 0;
          this.timerOffset = null;
          this.timerDisplay = '0:00:00';

          this.task.TimeSpent = response.data.TimeSpent;
        });
    },

    clearTimer() {
      this.$http
        .delete(`${process.env.VUE_APP_API}/tasks/timer`, {
          params: { taskID: this.editedTask.TaskID },
        })
        .then(() => {
          this.timerTotal = 0;
          this.timerOffset = null;
          this.timerDisplay = '0:00:00';
        });
    },

    getTimer() {
      this.timerOffset = null;
      this.timerTotal = 0;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      this.$http
        .get(`${process.env.VUE_APP_API}/tasks/timer`, {
          params: { taskID: this.editedTask.TaskID },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.timerOffset = response.data[0].UTCTimeStamp;
            this.timerTotal = response.data[0].AdditionalTime;

            if (response.data[0].UTCTimeStamp) {
              this.timerInterval = setInterval(this.updateTimer, 100);
            }

            this.updateTimer();
          } else {
            this.updateTimer();
          }
        });
    },

    amendTimer() {
      this.showAmendTime = true;

      const s = Math.floor(this.task.TimeSpent / 1000);
      const m = Math.floor(s / 60);
      const h = Math.floor(m / 60);

      this.timerSeconds = s % 60;
      this.timerMinutes = m % 60;
      this.timerHours = h;
    },

    saveAmended() {
      const h = !Number.isNaN(this.timerHours)
        ? parseInt(this.timerHours, 10)
        : 0;
      const m = !Number.isNaN(this.timerMinutes)
        ? parseInt(this.timerMinutes, 10)
        : 0;
      const s = !Number.isNaN(this.timerSeconds)
        ? parseInt(this.timerSeconds, 10)
        : 0;

      const time = ((h * 60 + m) * 60 + s) * 1000;

      this.$http
        .put(`${process.env.VUE_APP_API}/tasks/amendTime`, {
          taskID: this.editedTask.TaskID,
          time,
        })
        .then(() => {
          this.task.TimeSpent = time;
          this.showAmendTime = false;
        });
    },
  },
};
</script>

<style scoped>
.task-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-column {
  flex: 1;
}

.details-column {
  flex: 3;
}

.save-buttons {
  flex: 1;
  text-align: right;
}

.task-heading {
  font-size: 16px;
}

.table-row {
  display: flex;
  align-items: center;
}

.table-row:nth-child(even) {
  background: #ddd;
}

.table-row:nth-child(odd) {
  background: #eaeaea;
}

.table-text {
  flex: 3;
}

.pointer {
  cursor: pointer;
}

.table-text-selected {
  color: #7e7e7e;
}

.table-button {
  display: flex;
}

.table-status-column {
  flex: 1;
}

.table-timestamp-column {
  flex: 1;
}

.button-edit,
.button-add,
.button-remove {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tasksearch {
  width: calc(100% - 8px);
}

.input-time {
  width: 30px;
  font-size: 12px;
}

.button-small {
  padding: 4px;
  font-size: 11px;
}

textarea {
  width: calc(100% - 8px);
}

select {
  width: 100%;
}

div {
  margin-bottom: 2px;
}
</style>
