<template>
  <div class="task-filters">
    <div>
      <div>Task Name</div>
      <div>
        <input v-model="textFilter" placeholder="Filter tasks..." />
      </div>
    </div>
    <div>
      <div>Task Type</div>
      <div>
        <select v-model="typeFilter">
          <option value="-1">All</option>
          <option
            v-for="type in taskTypes"
            :key="type.TaskTypeID"
            :value="type.TaskTypeID"
          >
            {{ type.Name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="taskCompletedDateFilter">
      <div>Completed On</div>
      <div><input v-model="completedDateFilter" type="date" /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['task-completed-date-filter'],

  data() {
    return {
      taskTypes: [],

      textFilter: '',
      typeFilter: '-1',
      completedDateFilter: '',
    };
  },

  methods: {
    getTaskTypes() {
      // Populate task types list
      this.$http.get(`${process.env.VUE_APP_API}/tasktypes`).then((result) => {
        this.taskTypes = result.data;
      });
    },
  },

  watch: {
    textFilter() {
      this.$emit('task-filter-text-changed', this.textFilter);
    },
    typeFilter() {
      this.$emit('task-type-filter-changed', this.typeFilter);
    },
    completedDateFilter() {
      this.$emit(
        'task-completed-date-filter-changed',
        this.completedDateFilter
      );
    },
    taskCompletedDateFilter(newValue) {
      this.completedDateFilter = newValue;
    },
  },

  mounted() {
    this.getTaskTypes();
  },
};
</script>

<style scoped>
.task-filters {
  display: flex;
}

.task-filters > * {
  margin-right: 5px;
}
</style>
