<template>
  <div>
    <div class="groups-header">
      <list-button @click="showGroupList = !showGroupList">
        Task Groups
      </list-button>
      <button
        v-if="showGroupList && !showGroupForm"
        class="button-blue"
        @click="newGroup()"
      >
        New Group
      </button>
    </div>
    <div v-if="showGroupList && showGroupForm">
      <div class="editor-row">
        <div class="label-column">Name</div>
        <div class="details-column">
          <input v-model="editedGroup.Name" />
          <button class="button-small" @click="saveGroup()">Save</button>
          <button class="button-small" @click="cancelAddGroup()">Cancel</button>
        </div>
      </div>
    </div>
    <div v-if="showGroupList">
      <div
        class="table-row"
        v-for="group in groups"
        :key="group.TaskGroupID"
        @contextmenu="showContextMenu($event, group)"
      >
        <div
          class="table-text pointer"
          :class="{
            'table-text-selected': group.TaskGroupID === selectedTaskGroupID,
          }"
          @click="filterTasksByGroup(group)"
        >
          <span v-if="editedGroup.TaskGroupID !== group.TaskGroupID">
            {{ group.Name }}
          </span>
          <input
            v-if="editedGroup.TaskGroupID === group.TaskGroupID"
            v-model="group.Name"
          />
        </div>
        <div
          v-if="editedGroup.TaskGroupID === group.TaskGroupID"
          class="table-button"
        >
          <button class="button-edit icon-button" @click="saveGroup()">
            <img
              style="height: 14px"
              src="@/assets/done_FILL0_wght700_GRAD0_opsz48.svg"
            />
          </button>
          <button class="button-edit icon-button" @click="cancelEditGroup()">
            <img
              style="height: 14px"
              src="@/assets/close_FILL0_wght700_GRAD0_opsz48.svg"
            />
          </button>
        </div>
        <div
          v-if="editedGroup.TaskGroupID !== group.TaskGroupID"
          class="table-button"
        >
          <button class="button-edit icon-button" @click="editGroup(group)">
            <img
              style="height: 14px"
              src="@/assets/edit_FILL0_wght700_GRAD0_opsz48.svg"
            />
          </button>
          <button
            class="button-remove icon-button-red"
            @click="deleteGroup(group)"
          >
            <img
              class="icon-white"
              style="height: 14px"
              src="@/assets/delete_FILL0_wght700_GRAD0_opsz48.svg"
            />
          </button>
        </div>
      </div>
    </div>
    <context-menu ref="planningMenu">
      <li @click="clickContextMenu($event)">Move Tasks to Active</li>
    </context-menu>
  </div>
</template>

<script>
import ContextMenu from './ContextMenu.vue';
import ListButton from './ListButton.vue';

export default {
  components: { ContextMenu, ListButton },
  props: ['groups', 'projectid', 'view'],

  data() {
    return {
      showGroupForm: false,
      showGroupList: false,

      editedGroup: {
        Name: '',
      },
      oldGroup: {},
      selectedTaskGroupID: '-1',

      contextGroup: {},
    };
  },

  methods: {
    showContextMenu(e, group) {
      if (this.view === 'planning') {
        e.preventDefault();
        this.contextGroup = group;
        this.$refs.planningMenu.open(e);
      }
    },
    clickContextMenu(e) {
      this.$refs.planningMenu.close(e);
      this.$emit('move-to-active', this.contextGroup);
    },

    getGroups() {
      this.$http
        .get(`${process.env.VUE_APP_API}/groups`, {
          params: { projectID: this.projectid },
        })
        .then((response) => {
          this.projectGroups = response.data;
          this.$emit('get-groups', this.projectGroups);
        });
    },

    newGroup() {
      this.editedGroup = { Name: '' };
      this.showGroupForm = true;
    },

    editGroup(group) {
      this.showGroupForm = false;
      this.editedGroup = group;
      this.oldGroup = JSON.parse(JSON.stringify(group));
    },

    saveGroup() {
      this.showGroupForm = false;

      if (this.editedGroup.TaskGroupID) {
        this.updateGroup(this.editedGroup);
        this.editedGroup = {};
      } else {
        this.addGroup(this.editedGroup);
      }
    },

    cancelAddGroup() {
      this.showGroupForm = false;
    },

    cancelEditGroup() {
      if (this.oldGroup.TaskGroupID) {
        this.editedGroup.Name = this.oldGroup.Name;
      }
      this.oldGroup = {};
      this.editedGroup = {};
    },

    addGroup(group) {
      let newGroup = group;
      newGroup = {
        ProjectID: this.projectid,
        Name: group.Name,
      };

      this.$http
        .post(`${process.env.VUE_APP_API}/groups`, newGroup)
        .then(() => {
          this.getGroups();
        });
    },

    updateGroup(group) {
      this.$http.put(`${process.env.VUE_APP_API}/groups`, group).then(() => {
        this.getGroups();
      });
    },

    deleteGroup(group) {
      if (window.confirm('Confirm delete?')) {
        this.$http
          .delete(`${process.env.VUE_APP_API}/groups`, {
            params: { groupID: group.TaskGroupID },
          })
          .then(() => {
            this.getGroups();
            this.$emit('get-tasks');
          });
      }
    },

    filterTasksByGroup(group) {
      if (!this.editedGroup.TaskGroupID) {
        if (this.selectedTaskGroupID !== group.TaskGroupID) {
          this.selectedTaskGroupID = group.TaskGroupID;
        } else {
          this.selectedTaskGroupID = '-1';
        }
        this.$emit('set-task-group-filter', group);
      }
    },
  },

  created() {
    this.getGroups();
  },
};
</script>

<style scoped>
.editor-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-column {
  flex: 1;
}

.details-column {
  flex: 3;
}

.table-row {
  display: flex;
  align-items: center;
}

.table-row:nth-child(even) {
  background: #ddd;
}

.table-row:nth-child(odd) {
  background: #eaeaea;
}

.table-text {
  flex: 3;
}

.pointer {
  cursor: pointer;
}

.table-text-selected {
  color: #7e7e7e;
}

.table-button {
  display: flex;
}

.button-edit,
.button-add,
.button-remove {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-small {
  padding: 4px;
  font-size: 11px;
}

.groups-header {
  display: flex;
  justify-content: space-between;
}

select {
  width: 100%;
}

div {
  margin-bottom: 2px;
}
</style>
