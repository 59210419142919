<template>
  <div class="home">
    <project-board msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import ProjectBoard from '../components/ProjectBoard.vue';

export default {
  name: 'HomeView',
  components: {
    ProjectBoard,
  },
};
</script>
