<template>
  <div
    class="context-menu"
    v-show="show"
    :style="style"
    ref="context"
    tabindex="0"
    @blur="close"
  >
    <ul class="context-menu-list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    display: Boolean,
  },

  data() {
    return {
      left: 0,
      top: 0,
      show: false,
    };
  },

  computed: {
    style() {
      return {
        top: `${this.top}px`,
        left: `${this.left}px`,
      };
    },
  },

  methods: {
    close() {
      this.show = false;
      this.left = 0;
      this.top = 0;
    },
    open(evt) {
      this.left = evt.pageX || evt.clientX;
      this.top = (evt.pageY || evt.clientY) - window.pageYOffset;

      this.$nextTick(() => this.$el.focus());
      this.show = true;
    },
  },
};
</script>

<style scoped>
.context-menu {
  position: fixed;
  background: white;
  z-index: 999;
  outline: 1px;
  padding: 1px;
  font-size: 12px;
  font-weight: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.context-menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 5px;
}

li:hover {
  background-color: #94cbff;
  cursor: default;
}
</style>
