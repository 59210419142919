<template>
  <div
    class="card"
    :class="{
      'card-swimlane': view !== 'planning',
      'card-planning': view === 'planning',
    }"
    @click="$emit('select-task', task.Id)"
    @dragstart="!task.readOnly ? $emit('dragstart', $event, task) : {}"
    @contextmenu="showContextMenu"
  >
    <div class="card-content" :class="{ selected: selected }">
      <div v-bind:class="header"></div>
      <div class="card-body">
        <span draggable>{{ displayName }}</span>
      </div>
    </div>
    <context-menu ref="planningMenu">
      <li @click="clickMoveToActive($event)">Move to Active</li>
    </context-menu>
    <context-menu ref="activeMenu">
      <li @click="clickMoveToBacklog($event)">Move to Backlog</li>
    </context-menu>
  </div>
</template>

<script>
import ContextMenu from './ContextMenu.vue';

export default {
  props: ['task', 'selected', 'view'],

  components: { ContextMenu },

  data() {
    return {};
  },

  methods: {
    showContextMenu(e) {
      if (this.view === 'planning') {
        e.preventDefault();
        this.$refs.planningMenu.open(e);
      } else {
        e.preventDefault();
        this.$refs.activeMenu.open(e);
      }
    },
    clickMoveToActive(e) {
      this.$refs.planningMenu.close(e);
      this.$emit('move-to-active', this.task);
    },
    clickMoveToBacklog(e) {
      this.$refs.activeMenu.close(e);
      this.$emit('move-to-backlog', this.task);
    },
  },

  computed: {
    header() {
      return {
        'card-header': true,
        'card-header-programming': this.task.TaskTypeID === 1,
        'card-header-design': this.task.TaskTypeID === 2,
        'card-header-bug': this.task.TaskTypeID === 3,
      };
    },

    displayName() {
      if (this.task.Name.length > 100) {
        return `${this.task.Name.substr(0, 99)}...`;
      }
      return this.task.Name;
    },
  },
};
</script>

<style scoped>
.card {
  height: 100px;
}

.card-swimlane {
  display: flex;
  margin: 10px auto 10px auto;
  max-width: 210px;
}

.card-planning {
  width: 210px;
}

.card-content {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 5px #555;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #888;
  overflow: hidden;
}

.card-content:hover {
  cursor: pointer;
  filter: brightness(95%);
}

.card-content:active {
  filter: brightness(92%);
}

.card-header {
  height: 100%;
  min-width: 5px;
  pointer-events: none;
}

.card-header-programming {
  background-color: #2d57b3;
}

.card-header-design {
  background-color: #31b32d;
}

.card-header-bug {
  background-color: #b32d2d;
}

.card-body {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  overflow: hidden;
}

.selected {
  filter: brightness(88%);
}

.selected:hover {
  filter: brightness(85%);
}

.selected:active {
  filter: brightness(90%);
}
</style>
