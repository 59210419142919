<template>
  <button
    class="button-list"
    :class="{ 'button-list-selected': showList }"
    @click="$emit('click', (showList = !showList))"
  >
    <slot></slot>
    <img
      :class="{ 'button-list-selected-img': showList }"
      style="height: 24px"
      src="@/assets/arrow_right_FILL0_wght400_GRAD0_opsz48.svg"
    />
  </button>
</template>

<script>
export default {
  data() {
    return {
      showList: false,
    };
  },
};
</script>

<style scoped>
.button-list {
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  padding: 0;
  display: flex;
  align-items: center;
  height: 28px;
  text-decoration: underline;
}

.button-list:hover {
  background-color: rgba(0, 0, 0, 0);
}

.button-list-selected {
  color: #5094cc;
}

.button-list-selected-img {
  transform: rotate(90deg);
}
</style>
