<template>
  <div>
    <div>
      <div class="header">
        <div class="new-project">
          <button
            v-if="!displayForm"
            class="button-blue"
            @click="displayForm = !displayForm"
          >
            New Project
          </button>
          <transition name="newproject" mode="out-in">
            <div v-if="displayForm">
              <div>
                <input type="text" v-model="newProject.Name" />
                <button @click="saveProject()">Save</button>
                <button @click="displayForm = !displayForm">Cancel</button>
              </div>
            </div>
          </transition>
        </div>
        <project-filter
          :projects="projects"
          :visible-project-list="visibleProjectList"
          v-on:list-changed="visibleListChanged"
          label="Projects List"
        ></project-filter>
      </div>
    </div>
    <task-filters
      :task-completed-date-filter="taskCompletedDateFilter"
      v-on:task-filter-text-changed="taskFilterTextChanged"
      v-on:task-type-filter-changed="taskTypeFilterChanged"
      v-on:task-completed-date-filter-changed="taskCompletedDateFilterChanged"
    ></task-filters>
    <div v-for="project in projects" :key="project.ProjectID">
      <transition name="fadegrid">
        <task-board
          v-if="visibleProjectList.includes(project.ProjectID)"
          :project="project"
          :display-header="project.ProjectID === visibleProjectList[0]"
          :task-filter-text="taskFilterText"
          :task-type-filter="taskTypeFilter"
          :task-completed-date-filter="taskCompletedDateFilter"
          v-on:get-projects="getProjects"
        ></task-board>
      </transition>
    </div>
  </div>
</template>

<script>
import store from '../store';
import TaskBoard from './TaskBoard.vue';
import ProjectFilter from './ProjectFilter.vue';
import TaskFilters from './TaskFilters.vue';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export default {
  components: {
    TaskBoard,
    ProjectFilter,
    TaskFilters,
  },

  data() {
    return {
      displayForm: false,
      newProject: {
        Id: 0,
        Name: '',
      },

      projects: [],
      visibleProjectList: [],

      showList: false,

      taskFilterText: '',
      taskTypeFilter: '-1',
      taskCompletedDateFilter: '',
    };
  },

  methods: {
    getProjects() {
      this.$http.get(`${process.env.VUE_APP_API}/projects`).then((result) => {
        this.projects = result.data;
      });
    },

    saveProject() {
      // "Validation"
      if (this.newProject.Name === '') {
        return;
      }

      this.$http
        .post(`${process.env.VUE_APP_API}/projects/newProject`, this.newProject)
        .then(() => {
          this.displayForm = false;
          this.newProject.Name = '';
          this.getProjects();
        });
    },

    visibleListChanged(list) {
      this.visibleProjectList = list;
      localStorage.visibleProjectList = JSON.stringify(this.visibleProjectList);
    },

    getSettings() {
      this.$http
        .get(`${process.env.VUE_APP_API}/settings`)
        .then((result) => {
          store.settings.completedTasksNumDays = result.data.find(
            (s) => s.SettingName === 'CompletedTasksLastDays'
          ).SettingValue;
          store.settings.completedTasksReadOnlyNumDays = result.data.find(
            (s) => s.SettingName === 'CompletedTasksReadOnlyDays'
          ).SettingValue;

          let filterDate = new Date(
            Date.now() - store.settings.completedTasksNumDays * MS_PER_DAY
          );
          const offset = filterDate.getTimezoneOffset();
          filterDate = new Date(filterDate.getTime() - offset * 60 * 1000);
          [this.taskCompletedDateFilter] = filterDate.toISOString().split('T');
        })
        .then(() => {
          this.getProjects();
        });
    },

    taskFilterTextChanged(value) {
      this.taskFilterText = value;
    },
    taskTypeFilterChanged(value) {
      this.taskTypeFilter = value;
    },
    taskCompletedDateFilterChanged(value) {
      this.taskCompletedDateFilter = value;
    },
  },

  created() {
    this.getProjects();

    if (localStorage.visibleProjectList) {
      this.visibleProjectList = JSON.parse(localStorage.visibleProjectList);
    }

    this.getSettings();
  },
};
</script>

<style scoped>
.fadegrid-enter-active,
.fadegrid-leave-active {
  transition: all 0.5s;
  max-height: 100vh;
  overflow: hidden;
}

.fadegrid-enter,
.fadegrid-leave-to {
  opacity: 0;
  max-height: 0px;
}

.newproject-enter-active,
.newproject-leave-active {
  transition: max-width 0.1s;
}

.newproject-enter,
.newproject-leave-to {
  opacity: 0;
  max-width: 90px;
}

.newproject-enter-to,
.newproject-leave {
  opacity: 0;
  max-width: 300px;
}

.header {
  display: flex;
  width: 100%;
}

.header > * {
  margin-right: 5px;
}

.header-spacer {
  flex-grow: 1;
}

.new-project {
  height: 30px;
}
</style>
